import React from 'react'
import { Link } from 'theme-ui'
import { FaHeart } from 'react-icons/fa'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Text } from 'theme-ui'

const styles = {
  link: {
    color: `heading`,
    fontWeight: `medium`,
    ':visited': {
      color: `heading`
    },
    marginTop: '0px'
  },
  paragraph: {
      marginTop: '25px',
    //   color: 'black',
      textAlign: 'center'

  }
}

const PoweredByGatsby = () => (
  <div>
    <Text variant='p' sx={styles.paragraph} >Made with &nbsp;<FaHeart />&nbsp;&nbsp;by</Text>
    <Link
        variant='mute'
        target='_blank'
        title='Webdesk Designs'
        href='https://www.webdeskdesigns.com'
        rel='noopener'
        sx={styles.link}
    >
        <Img
            height={24}
            src='../../../../content/assets/webdesk.png'
            alt='Webdesk Designs'
        />
    </Link>
  </div>
)

export default PoweredByGatsby
